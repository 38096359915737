$dark: #272b3a;
$dark-2: #333333;
$dark-3: #222222;
$black: #000000;
$default-red: #dc5b4d;
$default-teal: #4dbbb6;
$default-yellow: #fbc15c;
$default-gray: #828282;
$default-blue: #b3deed;
$gray-1: #4f4f4f;
$gray-2: #bdbdbd;
$gray-3: #f2f2f2;
$white: #ffffff;
$white-alt: #fbfbfb;
$off-white: #f4f7fb;
