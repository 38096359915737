@font-face {
	font-family: "BD-Bold";
	src: url("./assets/fonts/Brandon-Grotesque/Brandon_blk.otf");
}

@font-face {
  font-family: "BD";
  src: url("./assets/fonts/Brandon-Grotesque/Brandon_reg.otf");
}

@font-face {
  font-family: "BD";
  src: url("./assets/fonts/Brandon-Grotesque/Brandon_bld.otf");
  font-weight: 700
}

@font-face {
  font-family: "HK-Bold";
  src: url("./assets/fonts//HK-Grotesk/HKGrotesk-Bold.otf");
}

@font-face {
  font-family: "HK-Normal";
  src: url("./assets/fonts/HK-Grotesk/HKGrotesk-Regular.otf");
}
@font-face {
	font-family: "HK-Normal";
	src: url("./assets/fonts/HK-Grotesk/HKGrotesk-Bold.otf");
	font-weight: 700
}
@import "./styles/main.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  background: $gray-3;
  font-family: "HK-Normal";
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.country-select-dropdown {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  background: $white;
  top: 370px;

  .ant-select-item {
    padding: 1rem;
    font-family: "HK-Bold";
    color: $black;
    font-size: 16px;
  }
}

@media screen and (min-width: 2000px) {
  .country-select-dropdown {
    .ant-select-item {
      font-size: 18px;
    }
  }
}

#root {
  height: 100vh;
}