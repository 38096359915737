@import "../../../styles/base/variables";

.issue-form-container {
	.issue-card-container {
		margin-bottom: 6rem;
		display: flex;
		justify-content: center;
		width: 1200px;
		margin: 4rem auto;

		.issue-card {
			width: 200px;
			margin-right: 0.6rem;
			height: 386px;
			border-radius: 30px;
			padding: 1.5rem;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			transition: 0.4s ease-out;
			perspective: 1000px;

			&:hover {
				width: 240px;
			}

			h3 {
				font-family: "HK-Bold";
				font-size: 20px;
				color: $white;
				width: 85%;
				line-height: 26px;
			}

			.card-image {
				width: 200px;
				height: 200px;
				position: absolute;
				background-repeat: no-repeat;
			}
		}

		.issue-card.danger {
			background: $default-red;

			.card-image {
				bottom: -35px;
				right: -21px;
			}
		}

		.issue-card.teal {
			background: $default-teal;

			.card-image {
				bottom: -50px;
				right: -6px;
			}
		}

		.issue-card.blue {
			background: $default-blue;
	
			.card-image {
				bottom: -35px;
				right: -2px;
				background-size: contain;
			}
		}

		.issue-card.yellow {
			background: #fbc15c;

			.card-image {
				bottom: 30px;
				right: -2px;
				background-size: contain;
			}
		}

		.issue-card.gray {
			background: #95b9c1;

			.card-image {
				bottom: -26px;
				right: -22px;
				background-size: contain;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.issue-card-container {
			width: 1000px;
			flex-wrap: wrap;

			.issue-card {
				width: 240px;
				margin-bottom: 1rem;
				margin-left: auto;
				height: 380px;
				margin-right: auto;

				.card-image {
					width: 180px;
					right: 0 !important;
				}
			}

			.issue-card.teal {
				.card-image {
					right: -10px !important;
					bottom: -30px !important;
				}
			}

			.issue-card.blue {
				.card-image {
					right: 1px !important;
				}
			}

			.issue-card.yellow {
				.card-image {
					bottom: -29px !important;
					right: 0px !important;
				}
			}

			.issue-card.gray {
				.card-image {
					right: -3px !important;
				}
			}
		}
	}

	@media screen and (max-width: 1020px) {
		.issue-card-container {
			width: 750px;
			flex-wrap: wrap;

			.issue-card {
				width: 240px;
				margin-bottom: 1rem;
				margin-left: auto;
				height: 380px;
				margin-right: auto;

				.card-image {
					width: 200px;
					right: 0 !important;
				}
			}

			.issue-card.teal {
				.card-image {
					right: -10px !important;
					bottom: -30px !important;
				}
			}

			.issue-card.blue {
				.card-image {
					right: 1px !important;
				}
			}

			.issue-card.yellow {
				.card-image {
					bottom: -10px !important;
					right: 20px !important;
				}
			}

			.issue-card.gray {
				.card-image {
					right: -3px !important;
				}
			}
		}
	}

	@media screen and (max-width: 769px) {
		.heading {
			h2 {
				line-height: 48px;
				font-size: 32px !important;
			}
		}

		.issue-card-container {
			width: 500px;
			flex-wrap: wrap;

			.issue-card {
				width: 400px;
				margin-bottom: 1rem;
				margin-left: auto;
				height: 300px;
				margin-right: auto;

				.card-image {
					width: 200px;
					right: 0 !important;
				}
			}

			.issue-card.teal {
				.card-image {
					right: -10px !important;
					bottom: -30px !important;
				}
			}

			.issue-card.blue {
				.card-image {
					right: 1px !important;
				}
			}

			.issue-card.yellow {
				.card-image {
					bottom: -10px !important;
					right: 80px !important;
				}
			}

			.issue-card.gray {
				.card-image {
					right: -3px !important;
				}
			}
		}
	}

	@media screen and (max-width: 480px) {
		.heading {
			h2 {
				line-height: 40px;
				font-size: 24px !important;
			}
		}

		.issue-card-container {
			width: 300px;
			flex-wrap: wrap;

			.issue-card {
				width: 290px;
				margin-bottom: 1rem;
				margin-left: auto;
				margin-right: auto;

				.card-image {
					width: 200px;
					right: 0 !important;
				}
			}

			.issue-card.teal {
				.card-image {
					right: -10px !important;
					bottom: -30px !important;
				}
			}

			.issue-card.blue {
				.card-image {
					right: 1px !important;
				}
			}

			.issue-card.yellow {
				.card-image {
					bottom: -10px !important;
					right: 16px !important;
				}
			}
		}
	}

	@media screen and (min-width: 1200px) {
		.issue-card-container {
			width: 1050px;
			flex-wrap: wrap;

			.issue-card {
				width: 240px;
				margin-bottom: 1rem;
				margin-left: auto;
				height: 380px;
				margin-right: auto;

				.card-image {
					width: 180px;
					right: 0 !important;
				}
			}

			.issue-card.teal {
				.card-image {
					right: -10px !important;
					bottom: -30px !important;
				}
			}

			.issue-card.blue {
				.card-image {
					right: 1px !important;
				}
			}

			.issue-card.yellow {
				.card-image {
					bottom: -30px !important;
					right: 20px !important;
				}
			}

			.issue-card.gray {
				.card-image {
					right: -3px !important;
				}
			}
		}
	}
}
