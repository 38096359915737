@import "../../../styles/base/variables";
@import "../../../styles/base/mixins";

.nav-container {
  .logo {
    display: flex;
    align-items: flex-start;
    height: 78px;
    .beta-tag {
      position: absolute;
      left: -60px;
      @media screen and (max-width: 992px) {
        left: 0;
        bottom: -18px;
      }
      @media screen and (max-width: 575px) {
        bottom: -4px;
      }
      .ant-badge {
        background: $default-red;
        color: $white;
        display: inline-block;
        padding: 4px 7px;
        font-family: "HK-Bold";
        font-size: 18px;
        width: 60px;
      }
    }
    img {
      width: 150px;
      @media screen and (max-width: 992px) {
        // margin-left: 18px;
      }
    }
    .language-selector {
      margin-left: 1.6rem;
      height: 100%;
      display: flex;
      align-items: center;
      .ant-select .ant-select-selector {
        background: $gray-3;
        border: none;
        .ant-select-selection-item {
          display: inline-flex;
          align-items: center;
          svg {
            width: 18px;
            height: 18px;
          }
          .language-text {
            display: inline-block;
            margin-left: 0.4rem;
            color: $dark-3;
            font-family: Helvetica;
            font-weight: normal;
            font-size: 12px;
          }
        }
        .ant-select-arrow {
          top: 50%;
        }
      }
    }
  }
  .link-btn-container {
    display: flex;
    justify-content: flex-end;
    .link-btn {
      background: $default-red;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white;
      padding: 0 2rem;
      height: 53px;
      font-weight: 700;
      &:hover {
        color: $white;
      }
    }
  }
  @include screenSize(ss-phone) {
    .ant-row {
      align-items: flex-start;
    }
    .logo {
      img {
        width: 120px;
      }
      .language-selector {
        margin-left: 0.6rem;
      }
    }
    .link-btn {
      padding: 0 1rem !important;
    }
  }
}
