@import "../../styles/base/variables";

.status-container {
	.status-form {
		.heading {
			text-align: center;
			h2 {
				font-family: "BD-Bold";
				font-size: 32px;
				color: $black;
			}
			p {
				font-family: "Hk-Normal";
				font-size: 1rem;
				color: $gray-1;
			}
		}
	}

	.status-btn {
		background: $default-yellow;
		color: $white;
		border-radius: 30px;
		height: 60px;
		width: 200px;
		font-size: 16px;
	}

	@media screen and (max-width: 1020px) {
		/*CSS for PC desktop (bigger than 1020px)*/
	}

	@media screen and (max-width: 767px) {
		/*CSS for tablet (bigger than 767px and smaller than 1020px)*/
	}
	@media screen and (max-width: 480px) {
		.status-btn {
			height: 50px;
		}
	}
}

@keyframes flip-with-scale {
	0% {
		transform: perspective(400px) scaleX(1);
	}

	100% {
		transform: perspective(400px) scaleX(-1);
	}
}
