@import "../../../styles/base/variables";
.result-container {
  .result-content {
    margin-top: 10px;
    .heading-wrap {
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    .heading {
      width: 40%;
      margin: auto;
      margin-bottom: 1rem;
      @media screen and (max-width: 992px) {
        width: 50%;
        margin-bottom: 0rem;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      img {
        height: 39px;
      }
      .help-container {
        justify-content: flex-end;
      }
      .help-text {
        display: flex;
        align-items: center;
        font-size: 1rem;
        img {
          width: 1rem;
        }
        span {
          font-family: "HK-Normal";
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }

    .result-slide-wrapper {
      width: 700px;
      margin: auto;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .result-footer {
      width: 41%;
      margin: auto;
      max-width: 490px;
      @media screen and (max-width: 992px) {
        width: 50%;
        max-width: 100%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .result-btn {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      @media screen and (max-width: 600px) {
        margin: 0rem 0 2rem 0;
      }
      .ant-btn,
      a.explore-link {
        font-family: "HK-Bold";
        font-weight: 700;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        outline: none;
        border: none;
        height: 60px;
      }
      .share-btn {
        background: $default-teal;
        color: $white;
        width: 55%;
        @media screen and (max-width: 900px) {
          width: 70%;
        }
        img {
          display: inline-block;
          margin-right: 4px;
        }
      }
      a.explore-link {
        border: 1px solid #2f3447;

        color: $black;
        width: 200px;

        &:hover {
          color: $black;
        }
        @media screen and (max-width: 900px) {
          width: 70%;
          margin-top: 1rem;
        }
      }
    }
  }
  .banner-container {
    // margin-bottom: 1rem;
  }
  .progress-bar {
    display: flex;
    @media screen and (max-width: 900px) {
      margin-top: 1rem;
    }
    span {
      height: 12px;
      width: 12px;
      display: block;
      background-color: $default-gray;
      border-radius: 50%;
      margin-right: 10px;
      &:first-child {
        background-color: #4dbbb5;
      }
    }
  }
}
