.flex {
  display: flex;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}

.mt-2rem {
  margin-top: 2rem;
}
.mb-2rem {
  margin-bottom: 2rem;
}
.z-10 {
  z-index: 10;
}
.slide-content {
  transition: all 0.3s;
}
.fad-in {
  transform: translateX(10rem);
  transition: all 0.3s;
}
