@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.explore-content-container {
	.heading {
		h3 {
			font-family: "BD-Bold";
			font-size: 48px;
			color: $black;
			margin-bottom: 3rem;
			text-align: center;
			margin-top: 10px;
			@media screen and (max-width: 600px) {
				margin-bottom: 0;
			  }
		}
	}
	.content {
		margin-top: 2rem;
		.card-image {
			width: 100%;
			height: 270px;
			background: $default-teal;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				&.content{
					margin: 0;
					object-fit: cover;
					width: 100%;
					height: 100%;
				}

				&.no-content {
					width: 60px;
					height: 80px;
				}
			}
		}
	}
	.explore-card {
		position: relative;
		min-height: 530px;
		height: auto;
		margin-bottom: 5rem;
		@media screen and (max-width: 600px) {
			padding-left: 12px;
			padding-right: 12px;
			margin-bottom: 5px;
		  }
		.card-content-wrapper {
			position: absolute;
			background: #fff;
			top: 45%;
			width: 90%;
			padding: 1.6rem 2rem;
			.language {
				font-family: "HK-Normal";
				color: $dark-2;
				font-size: 14px;
				margin-bottom: 1.5rem;
			}
			.card-heading {
				font-family: "BD-Bold";
				color: $black;
				font-size: 24px;
				margin-bottom: 14px;
			}
			.card-body {
				font-family: "HK-Normal";
				color: $dark-3;
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 2rem;
				overflow-y: hidden;
			}
			.card-footer {
				a {
					color: $default-red;
					font-family: "HK-Bold";
					font-size: 1rem;
				}
			}
		}
	}
	.explore-link-btn {
		margin: 4rem 0;
		text-align: center;
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			font-size: 1rem;
			font-family: "HK-Bold";
			color: $black;
			height: 60px;
			padding: 0 2.5rem;
			border: 1px solid $black;
		}
	}
	@include screenSize(ss-phone) {
	}
}
