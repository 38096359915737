@import "../../styles/base/variables";
@import "../../styles/base/mixins";

@mixin customTextBox {
  font-family: "HK-Normal";
  font-size: 18px;
  color: $dark-3;
  line-height: 34px;
  margin-bottom: 1.5rem;
}

.info-content-container {
  background-color: $off-white;
  .heading {
    h3 {
      font-family: "BD-Bold";
      font-size: 48px;
      text-align: center;
      letter-spacing: -1px;
      margin-top: 10px;
      @media screen and (max-width: 600px) {
        margin-top: 1rem;
      }
    }
  }
  .content {
    background: $white;
    border-radius: 13px;
    margin-top: 30px;
    padding: 80px;
    em {
      @include customTextBox;
      margin-bottom: 2rem;
      display: flex;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    ul {
      margin-inline: 1.5rem;
      list-style: none;
      @media screen and (max-width: 600px) {
        margin-inline: 0rem;
      }
      li {
        @include customTextBox;
      }
    }
  }
  .info-link-btn {
    margin: 2rem 0;
    text-align: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      font-size: 1rem;
      font-family: "HK-Bold";
      color: $black;
      height: 60px;
      padding: 0 2.5rem;
      border: 1px solid $black;
    }
  }
  @include screenSize(ss-phone) {
    .content {
      margin-top: 40px;
      padding: 30px;
      ul {
        margin-inline-start: 0;
      }
    }
  }
}
