.em-modal-container {
	.ant-modal-title {
		font-family: "BD-Bold";
		font-size: 18px;
	}
	.ant-modal-body {
		.social-list {
			display: flex;
			align-items: center;
			a {
				display: inline-flex;
				width: 40px;
				height: 40px;
				border-radius: 4px;
				text-decoration: none;
				margin-right: 1rem;
				padding: 10px;
				justify-content: center;
				align-items: center;

				svg {
					width: 25px;
					height: 25px;
					fill: #fff;
				}
			}
			a.fb {
				background: #4267b2;
			}
			a.twitter {
				background: #00acee;
			}
			a.ig {
				background: #3f729b;
			}
			a.ln {
				background: #0e76a8;
			}
		}
	}
}
