@import "../../styles/base/variables";

.page-layout-container {
  background-color: #f4f7fb;
  .page-content-container {
    height: calc(100vh - 40px);
    min-height: 400px;
    position: relative;
    @media screen and (max-width: 600px) {
      height: calc(100vh - 77px);
    }
  }
  .status-page {
    @media screen and (max-width: 600px) {
      height: calc(100vh - 180px);
    }
  }
}

.status-container {
  margin-top: 1rem;
  .nav-heading {
    background: $white-alt;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-bottom: 2rem;
    overflow: hidden;
    .ant-btn {
      display: flex;
      flex: 1;
      height: 50px;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      border: none;
      color: $gray-2;

      &::after {
        content: "";
        height: 12px;
        width: 1px;
        background: $black;
        border-left: 1px solid $dark-2;
        position: absolute;
        right: 0;
      }
    }

    .ant-btn:last-child {
      &::after {
        content: unset;
      }
    }
    .active-nav {
      background: $default-teal;
      color: $white !important;
      &::after {
        content: unset;
      }
    }
    .ant-btn[disabled] {
      color: $default-gray;
    }
    .visited-nav {
      color: $dark-2;
    }
    .visited-nav.no-border {
      &::after {
        content: unset;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    .nav-heading {
      .ant-btn {
        span {
          font-size: 20px !important;
        }
        height: 60px;
      }
    }
  }
  @media screen and (min-width: 2000px) {
    .nav-heading {
      .ant-btn {
        span {
          font-size: 20px !important;
        }
        height: 60px;
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .nav-heading {
      .ant-btn {
        span {
          font-size: 20px !important;
        }
        height: 60px;
      }
    }
  }
  @media screen and (max-width: 1560px) {
    .nav-heading {
      .ant-btn {
        span {
          font-size: 14px !important;
        }
        height: 50px;
      }
    }
  }

  @media screen and (max-width: 769px) {
    .nav-heading {
      .ant-btn {
        span {
          font-size: 14px !important;
        }
        height: 50px;
      }
    }
  }
}
