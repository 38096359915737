@import "../../../styles/base/variables";

.age-form-container {
  .age-form {
    margin-top: 2.8rem;
    .ant-input-number {
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
      width: 456px;
      border-color: transparent;
      &:hover {
        border-color: #40a9ff;
      }
      @media screen and (max-width: 600px) {
		  width: 85vw;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
      input {
        text-align: center;
        height: 70px;
        background: #ffffff;
        border-radius: 20px;
        font-size: 18px;
      }
    }
    &:placeholder-shown {
      text-align: center;
    }
    .btn-wrapper {
      margin-top: 2.8rem;
      text-align: center;
    }
  }
  .backdrop {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    img {
      width: 100%;
    }
  }
}
