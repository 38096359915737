@import "../../../../styles/base/variables";

.slide-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  overflow: hidden;

  .slide-btn {
    background: none;
    border: none;
  }

  .teal {
    background: $default-teal;
  }

  .red,//TODO, can be removed later
  .danger {
    background: $default-red;
  }

  .blue {
    background: $default-blue;
  }

  .yellow {
    background: $default-yellow;
  }

  .gray {
    background: $default-gray;
  }

  .result-card {
    min-width: 440px;
    padding: 2rem;
    color: $white;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    // filter: drop-shadow(15px 0px 20px rgba(0, 0, 0, 0.25));
    // -webkit-filter: drop-shadow(15px 0px 20px rgba(0, 0, 0, 0.25));
    // -webkit-box-shadow: 15px 0px 20px rgba(0, 0, 0, 0.25);
    box-shadow: 15px 0px 20px rgba(0, 0, 0, 0.25);
    transition: all 0.6s;

    @media screen and (max-width: 600px) {
      min-width: 100%;
      padding: 1rem;
      height: fit-content;
    }

    &-wrapper {
      position: relative;
      width: 480px;
      min-height: 440px;
      display: flex;

      @media screen and (max-width: 600px) {
        width: 70%;
        min-height: 312px;
      }
    }

    p {
      font-size: 24px;
      line-height: 33px;
      font-family: "BD";
      font-weight: 700;
      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 26px;
      }

      .highlighted {
        color: #000;
      }
    }

    .result-logo {
      width: 60px;

      @media screen and (max-width: 600px) {
        width: 40px;
      }

      img {
        width: 100%;
      }
    }

    .result-illustration {
      width: 200px;
      margin-bottom: -2rem;
      margin-right: -2rem;

      img {
        width: 100%;
      }
    }

    &-footer {
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .fact-number {
    font-family: "BD-Bold";
  }
}
.banner-container {
  flex: 0 !important;
}
.help-container {
  flex: unset !important;
}
