@import "../../../styles/base/variables";

.country-form-status {
  .country-form-container {
    .ant-select {
      margin-top: 24px;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
      justify-content: center;
    }
    .ant-select {
      width: 456px;
      margin: 0 auto;
	  @media screen and (max-width: 600px) {
		width: 100%;
	}
    }
    .ant-select-selector {
      height: 60px !important;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
      border-radius: 20px !important;
      border-color: transparent !important;
      width: 100%;
	  padding-left: 34px !important;
      input {
        height: 100% !important;
		padding-left: 24px !important;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        height: 60px;
        font-family: "HK-Bold";
      }
      .ant-select-selection-search-input {
        height: 60px;
      }
      .ant-select-selection-placeholder {
        height: 60px;
        display: flex;
        align-items: center;
      }
    }
    .btn-wrapper {
      text-align: center;
    }
  }

  .backdrop {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    img {
      width: 100%;
    }
  }  
}
